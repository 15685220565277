.animated {
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.animated-long {
  animation-duration: 1.5s;
  animation-fill-mode: both;

}

@keyframes floatAnimation {
  0%, 100% {
    transform: translate(-50%, -50%) translateY(0);
  }
  12.5% {
    transform: translate(-50%, -50%) translateY(-115px);
  }
  25% {
    transform: translate(-50%, -50%) translateY(0) translateX(115px);
  }
  37.5% {
    transform: translate(-50%, -50%) translateY(30px) translateX(115px);
  }
  62.5% {
    transform: translate(-50%, -50%) translateY(0) translateX(-115px);
  }
  75% {
    transform: translate(-50%, -50%) translateY(-30px) translateX(-115px);
  }
  87.5% {
    transform: translate(-50%, -50%) translateY(0) translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeInUp2 {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp2 {
  animation-name: fadeInUp2;
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.slideOutRight {
  animation-name: slideOutRight;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

@keyframes buttonhover {
  to {
    transform: rotate(360deg);
  }
}

@keyframes balloons {
  0% {
    transform: translateY(-10px) rotate(-2deg);
  }

  25% {
    transform: translateY(10px) rotate(2deg);
  }

  50% {
    transform: translateY(-10px) rotate(0deg);
  }

  75% {
    transform: translateY(10px) rotate(-2deg);
  }

  100% {
    transform: translateY(-10px) rotate(-2deg);
  }
}

@keyframes balloons2 {
  0% {
    transform: translateY(10px) rotate(2deg);
  }

  25% {
    transform: translateY(-10px) rotate(-2deg);
  }

  50% {
    transform: translateY(10px) rotate(0deg);
  }

  75% {
    transform: translateY(-10px) rotate(2deg);
  }

  100% {
    transform: translateY(10px) rotate(2deg);
  }
}

@keyframes boxanimate {

  0%,
  100% {
    right: 0;
    bottom: 0;
  }

  12% {
    right: 50%;
    bottom: -50%;
  }

  25% {
    right: 125%;
    bottom: -25%;
  }

  37% {
    right: 150%;
    bottom: 50%;
  }

  50% {
    right: 125%;
    bottom: 125%;
  }

  62% {
    right: 50%;
    bottom: 150%;
  }

  75% {
    right: -25%;
    bottom: 125%;
  }

  87% {
    right: -50%;
    bottom: 50%;
  }
}

@keyframes rotateInfinite {
  to {
    transform: rotate(360deg);
  }
}

.rotateInfinite {
  animation: rotateInfinite 15s linear infinite;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomOut {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
}

.zoomOut {
  animation-name: zoomOut;
}

@keyframes scrollDown {

  0%,
  100% {
    margin-top: 0;
  }

  50% {
    margin-top: 5px;
  }
}

.scrollDown {
  animation-name: scrollDown;
}