//import tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

//config
@import "~normalize.css/normalize.css";
@import "./keyframes.scss";
@import "./fonts.scss";

* {
  letter-spacing: -0.05rem;

  @screen sm {
    letter-spacing: 0rem;

  }
}

//base
@layer base {
  html {
    @apply text-white bg-black;
    overflow-x: hidden;
  }

  body {
    min-width: 375px;
  }
}

.project-box {
  backdrop-filter: blur(12px);
}

.project-box::after {
  background: radial-gradient(100% 100% at 0% 0%, rgba(250, 250, 250, 0.2) 0%, rgba(247, 247, 247, 0) 100%);
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 200px;
}

.project-box:before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 2px;
  border-radius: 200px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);



  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.is-invalid {
  position: relative;

  input,
  select,
  textarea {
    border-color: #ff6161 !important;
  }

  &-message {
    color: #ff6161;
    font-size: 10px;
    position: absolute;
    bottom: -17px;
    left: 0;

    @screen md {
      font-size: 13px;
      bottom: -22px;
    }
  }
}

//route transition animation
.fade-enter {
  opacity: 0;
}

.fade-exit {
  opacity: 1;
}

.fade-enter-active {
  opacity: 1;
  transition-delay: 0.5s !important;
}

.fade-exit-active {
  opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 0.5s;
}

.bar-blur {
  position: absolute;
  transition: none;
  background: none;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 450px;
  border-radius: 50%;
  background: linear-gradient(159.95deg, rgba(79, 75, 124, 0.12) -151.79%, rgba(52, 89, 164, 0.12) -105.04%);
  backdrop-filter: blur(10px);
  z-index: -1;
  aspect-ratio: 1;
  transition: 1s;

  @screen lg {
    max-width: 70vh;
  }

}