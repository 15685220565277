.h-scroll::-webkit-scrollbar {
  display: none;
}

.h-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.arrow {
  &:before {
    content: "";
    width: 16px;
    height: 16px;
    top: 15px;
    left: 0;
    position: absolute;
    border-radius: 100%;
    border: 2px solid #fff;
    left: calc(50% - 8px);
    animation: scrollDown 1.3s infinite;
  }
  &::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    border-top: 2px solid #ffffff;
    border-left: 2px solid #fff;
    transform: rotate(-315deg);
    top: 0;
    left: calc(50% - 6px);
    animation: scrollDown 2.6s infinite;
  }
}
