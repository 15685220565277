.dark-button {
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: top 0.4s;
  &:before {
    content: "";
    background: linear-gradient(332.73deg, #1e0c0b 34.02%, #0d1525 70.97%);
    position: absolute;
    z-index: -1;
    top: -150%;
    right: 0;
    bottom: -150%;
    left: 0;
    @screen lg {
      top: -100%;
      bottom: -100%;
    }
  }
  span {
    background: linear-gradient(
      122.5deg,
      #ffffff 23.06%,
      rgba(255, 255, 255, 0.5) 95.95%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  &:not(.no-hover-bg) {
    &:hover {
      &:before {
        animation: buttonhover 2s linear infinite;
      }
    }
  }

  &:not(.no-hover) {
    &:hover {
      top: -5px;
    }
  }
  &:not(.footarea) {
    padding: 14px 25px;
    min-width: auto;
    height: 62px;
    @screen md {
      padding: 14px 50px;
    }
    @screen lg {
      padding: 28px 55px;
      min-width: 260px;
      height: 90px;
    }
  }
  &.footarea {
    padding: 5.5px;
    line-height: 34px;
    font-size: 18px;
    min-width: 96px;
    border-radius: 16px;
    height: 46px;
    align-items: center;
    @screen lg {
      padding: 8px;
      height: 50px;
    }
    @screen xl {
      padding: 14px;
      font-size: 22px;
      min-width: 145px;
      border-radius: 30px;
      height: 62px;
    }
  }
}
