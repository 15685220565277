.emailform {
  &-input {
    background: linear-gradient(332.73deg, #000000 34.02%, #000000 70.97%);
    border: 1px solid rgba(255, 255, 255, 0.3);
    transition: border-color 0.4s;
    &:focus {
      outline: none;
      border-color: rgba(255, 255, 255, 0.7);
    }
    &.but-footarea {
      padding: 12px 111px 12px 15px;
      border-radius: 16px;
      font-size: 14px;
      @screen xl {
        padding: 18px 175px 18px 30px;
        border-radius: 30px;
        font-size: 16px;
      }
    }
  }
}
