footer {
  background: linear-gradient(259.8deg, #1e0c0b -6.39%, #040811 79.97%);
}

.footer {
  &-container {
    grid-template-columns: 97px 170px auto;
    @screen xl {
      grid-template-columns: 170px 180px auto;
    }
  }
}

.separator {
  position: relative;
  &:before {
    content: "";
    height: 1px;
    top: auto;
    width: 100%;
    position: absolute;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.3),
      transparent
    );
    right: 0;
    bottom: 0;
    @screen lg {
      right: -24px;
      top: -56px;
      width: 1px;
      height: 223px;
    }
    @screen 2xl {
      height: 247px;
      right: -48px;
    }
  }
}
