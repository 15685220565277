.header {
  &-fixed {
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 30px 5px rgb(0 0 0 / 74%);

    img {
      width: 97px;

      @screen lg {
        width: 130px;
      }
    }
  }

  &-enter {
    opacity: 0;
    transform: translateY(-150%);

    &-done {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .mobile-menu-btn {
    span {
      display: block;
      width: 25px;
      height: 2px;
      margin-bottom: 5px;
      position: relative;
      background: #fff;
      border-radius: 2px;
      z-index: 1;
      transform-origin: 4px 0px;
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

      &:first-child {
        transform-origin: 0% 0%;
        width: 17px;
      }

      &:nth-last-child(2) {
        transform-origin: 0% 100%;
        width: 25px;
      }

      &:nth-last-child(3) {
        width: 21px;
      }
    }
  }

  .mobile-menu-input {
    &:checked~.mobile-menu-btn span {
      opacity: 1;
      transform: rotate(45deg) translate(-6px, -9px);

      &:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }

      &:nth-last-child(2) {
        transform: rotate(-45deg) translate(-5px, 9px);
      }
    }

    &:checked~ul {
      transform: none;
      visibility: visible;

      li {
        animation-name: fadeInLeft;
        animation-duration: 0.4s;
        animation-fill-mode: both;
        animation-delay: 0.4s;
        animation-timing-function: cubic-bezier(0.77, 0.2, 0.05, 1);

        &:nth-child(1) {
          animation-delay: 0.4s;
        }

        &:nth-child(2) {
          animation-delay: 0.5s;
        }

        &:nth-child(3) {
          animation-delay: 0.6s;
        }

        &:nth-child(4) {
          animation-delay: 0.7s;
        }

        &:nth-child(5) {
          animation-delay: 0.8s;
        }

        &:nth-child(6) {
          animation-delay: 0.9s;
        }
      }
    }
  }

  .switch-border {
    padding: 3px;
    position: relative;
    width: 64px;
    height: 26px;
    overflow: hidden;
    background: url(../../assets/images/language-border-mobile.svg) no-repeat center center;

    @screen md {
      padding: 6px;
      width: 111px;
      height: 42px;
      background: url(../../assets/images/language-border.svg) no-repeat center center;
    }

    .switch {
      display: flex;
      cursor: pointer;
      overflow: hidden;
      background-color: transparent;
      border-radius: 10px;
      height: 100%;
      -webkit-tap-highlight-color: transparent;


      .en,
      .tr {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Bebas Neue;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        transition: color 0.3s ease-in-out;
        position: relative;
        z-index: 2;
        align-self: center;
        background: linear-gradient(122.5deg, #FFFFFF 23.06%, rgba(255, 255, 255, 0.5) 95.95%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @screen md {
          font-size: 22px;
        }
      }

      .en {
        color: rgba(5, 12, 25, 1);
        background: none;
        -webkit-background-clip: unset;
        -webkit-text-fill-color: unset;

      }

      .slider {
        width: 28px;
        height: 20px;
        background: #fff;
        transition: transform 0.3s ease-in-out;
        position: absolute;
        z-index: 1;
        border-radius: 6px;

        @screen md {
          width: 50px;
          height: 30px;
          border-radius: 10px;
        }
      }

      &.active {

        .en {
          background: linear-gradient(122.5deg, #FFFFFF 23.06%, rgba(255, 255, 255, 0.5) 95.95%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .tr {
          color: rgba(5, 12, 25, 1) !important;
          background: none;
          -webkit-background-clip: unset;
          -webkit-text-fill-color: unset;

        }

        .slider {
          transform: translateX(29px);

          @screen md {
            transform: translateX(49px);
          }
        }
      }
    }

  }
}

.main-menu {
  transition: transform 0.4s cubic-bezier(0.77, 0.2, 0.05, 1);
  width: 100% !important;
  justify-content: flex-end;
  align-items: center;

  li {
    @apply font-heading;

    a {
      background: linear-gradient(to right,
          #fff 50%,
          #8f93f8 65%,
          #9be0f8 90%,
          #fff 100%);
      background-size: 200% auto;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: opacity 0.3s;
      opacity: 0.65;

      &.active {
        animation: shine 3s ease-in-out infinite;
        opacity: 1;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}