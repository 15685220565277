@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.media {
  background: linear-gradient(311.63deg, #0d1525 29.54%, #1e2d3d 74.97%);
  box-shadow: 0px 14px 44px rgba(46, 29, 58, 0.51);
  &-tab {
    &--items {
      @apply flex gap-5;
    }
    &--item {
      @apply font-heading 2xl:text-xl lg:text-xl-t text-xl-m opacity-30 cursor-pointer duration-300 hover:opacity-50;
    }
  }
  &-box {
    @apply relative w-[435px] h-[340px] rounded-[40px] z-10 overflow-hidden flex items-end duration-300 top-0 hover:-top-1;
    box-shadow: 0px 14px 44px rgba(46, 29, 58, 0.51);
    border: 1px solid rgba(255, 255, 255, 0.05);
    h3 {
      background: linear-gradient(84.12deg, #8f93f8 27.03%, #9be0f8 98.96%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    img {
      @apply -z-10 object-cover w-full h-full absolute inset-0;
    }
    &--content {
      background: linear-gradient(0, #0d1525 29.54%, #1e2d3dd2 74.97%);
      @apply p-5 w-full;
      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
    }
  }
}

.newsroom {
  .slick-arrow {
    width: 24px;
    height: 41px;
    background: url("../../assets/images/icon-slide-right.svg") no-repeat;
    &:before {
      display: none;
    }
    &.slick-next {
      right: -40px;
      transition: 0.4s;
      &:hover {
        right: -45px;
      }
    }
    &.slick-disabled {
      opacity: 0.4;
    }
    &.slick-prev {
      transform: rotate(180deg);
      left: -40px;
      transition: 0.4s;
      margin-top: -21px;
      &:hover {
        left: -45px;
      }
    }
  }
}
