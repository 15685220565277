.box {
  box-shadow: 0px 14px 44px rgba(46, 29, 58, 0.51);
  width: 561px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 0px 20px 20px;
  display: inline-block;
  height: 300px;
  white-space: initial;
  margin-left: 1rem;

  @screen sm{
    width: 380px;
    height: 420px;
  }

  @screen md {
    padding: 0 20px 20px;
    border-radius: 40px;
    width: 488px;
    margin-left: 1.25rem;
    height: 420px;
  }

  @screen lg {
    padding: 0 30px 30px;
    width: 720px;
    height: 400px;
  }

  @screen 2xl {
    padding: 0 30px 50px;
    border-radius: 50px;
    width: 770px;
    margin-left: 30px;
    height: 470px;
  }

  &:first-child {
    margin-left: 0;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0px;
    right: 0;
    z-index: -1;
    transform: rotate(45deg);
  }

  &-s1 {
    background-color: #0d1525;

    &:before {
      box-shadow: 0px 0px 400px 400px #1e0c0b;
      animation: boxanimate 8s ease-in-out infinite;
    }
  }

  &-s2 {
    background: linear-gradient(311.63deg, #0d1525 29.54%, #1e2d3d 74.97%);

    &:before {
      box-shadow: 0px 0px 400px 400px #0d1525;
      animation: boxanimate 6s ease-in-out infinite;
    }
  }

  &-d1 {
    &:before {
      animation-duration: 7s;
    }
  }

  &-d2 {
    &:before {
      animation-duration: 6s;
    }
  }

  &-d3 {
    &:before {
      animation-duration: 5s;
    }
  }

  &-icon {
    height: 123px;

    img {
      max-width: 47%;

      @screen md {
        max-width: 55%;
      }

      @screen lg {
        max-width: 70%;
      }

      @screen 2xl {
        max-width: 100%;
      }
    }

    @screen md {
      height: 130px;
    }

    @screen lg {
      height: 160px;
    }

    @screen 2xl {
      height: 241px;
    }

    &-a1 {
      animation: balloons 11s ease-in-out infinite;
      transform: translateY(-10px) rotate(-2deg);
    }

    &-a2 {
      animation: balloons2 11s ease-in-out infinite;
      transform: translateY(10px) rotate(2deg);
    }
  }

  h3 {
    background: linear-gradient(to right, #8f93f8 0%, #9be0f8 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  p {
    @apply text-turquoise-blue;
  }
}