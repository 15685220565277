.bar {
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  transition: 1s;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 450px;
  background: url(../../assets/images/a.png) no-repeat center center;
  background-size: contain;
  border-radius: 50%;
  position: relative;

  @screen lg {
    max-width: calc(30vh + 370px);
    margin-bottom: 4rem;
    height: 70vh;
  }

}

.process {

  &-title {
    margin-bottom: 6px;
    font-family: Bebas Neue;
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    color: rgba(225, 226, 246, 1);
    letter-spacing: -0.07rem;

    @screen sm {
      letter-spacing: 0rem;
    }
    @screen xl {
      font-size: 32px;
      line-height: 38px;
    }

    @screen 2xl {
      font-size: 40px;
      line-height: 48px;
    }
  }

  &-icon {
    width: 60px;
    height: auto;

    @screen lg {
      width: 100px;
    }

    @screen 2xl {
      width: 105px;
    }
  }

  &-text {
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: rgba(104, 211, 243, 1);
    letter-spacing: -0.07rem;

    @screen sm {
      font-size: 12px;
      letter-spacing: 0rem;
    }

    @screen xl {
      font-size: 14px;
      line-height: 22px;
    }

    @screen 2xl {
      font-size: 16px;
      line-height: 26px;
    }
  }
}

div[data-test-id="CircularProgressbarWithChildren__children"] {
  position: absolute !important;
  max-width: 320px;
  height: 50% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin-top: auto !important;
  margin: auto;

  @screen md {
    max-width: 420px;
  }

  @screen lg {
    max-width: 400px;
  }

  @screen xl {
    max-width: 400px;
  }

  @screen 2xl {
    max-width: 500px;
  }
}